<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-click="competencia.length == 2"
      :close-on-content-click="false"
      transition="scroll-y-transition"
      offset-y
      right
      max-width="280px"
      min-width="280px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          dense
          readonly
          hide-details
          class="monthly-filter-field"
          placeholder="nenhuma"
          prepend-icon="mdi-calendar"
          :prefix="'Período:'"
          :value="textFieldValue"
          :clearable="clearable"
          @click:clear="competencia = null"
          @click:prepend.stop="on.click"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="competencia"
        no-title
        range
        @click:month="showMenu = oneMonth ? false : competencia.length != 2;"
        @change="changeHandler"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'day-filter',
  computed: {
    ...mapGetters(['intervaloData']),
    textFieldValue: function () {
      return `${this.$options.filters.toDate(this.competencia[0]) ? this.$options.filters.toDate(this.competencia[0]) : 'Sem data inicio'} até ${this.$options.filters.toDate(this.competencia[1]) ? this.$options.filters.toDate(this.competencia[1]) : 'Sem data fim'}`;
    },
  },
  created: function () {
    this.$emit('change', this.intervaloData);
  },
  data: function () {
    return {
      competencia: this.$store.getters.intervaloData,
      showMenu: false,
    }
  },
  methods: {
    ...mapMutations(['setIntervaloData']),
    changeHandler: function (value) {
      if (!value) {
        return;
      }
      this.setIntervaloData(value);
      this.$emit('change', value);
    },
  },
  model: {
    prop: 'interval',
    event: 'change'
  },
  props: {
    interval: {
      type: [Array, String],
      default: () => []
    },
    oneMonth: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.monthly-filter-field::v-deep {
  margin-top: 0;

  .v-input__control > .v-input__slot > .v-text-field__slot > input[readonly] {
    color: unset !important;
  }
}
</style>
